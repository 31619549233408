import * as msal from "@azure/msal-browser";

// An authentication service that uses the MSAL.js library to sign in users with
// either an AAD or MSA account. This leverages the AAD v2 endpoint.
class MsalAuthService {
  constructor(clientId, tenantId, scopes) {
    this.scopes = scopes;

    this.app = new msal.PublicClientApplication({
      auth: {
        clientId: clientId,
        redirectUri: `${window.location.origin}/browser-redirect`,
        authority: "https://login.microsoftonline.com/" + tenantId,
      },
    });
  }

  isCallback() {
    return this.app.handleRedirectPromise().then((authResponse) => {
      if (authResponse) {
        this.app.setActiveAccount(authResponse.account);
        return true;
      } else {
        return false;
      }
    });
  }

  login() {
    return new Promise((resolve, reject) => {
      // Configure all the scopes that this app needs
      const loginScopes = ["openid", "email", "profile", "offline_access", "User.Read"];

      const authRequest = {
        scopes: loginScopes,
        prompt: "select_account",
      };

      if (window.navigator.standalone) {
        resolve(this.app.loginRedirect(authRequest));
      } else {
        // This method is called for browser auth only outside teams
        this.app
          .loginPopup(authRequest)
          .then((authResponse) => {
            this.app.setActiveAccount(authResponse.account);

            resolve(authResponse.account);
          })
          .catch((error) => {
            reject(Promise.reject(error));
          });
      }
    });
  }

  logout() {
    this.app.logout();
  }

  getUser() {
    let activeAccount = this.app.getActiveAccount();
    if (!activeAccount) {
      const allAccounts = this.app.getAllAccounts();
      if (allAccounts.length === 1) {
        this.app.setActiveAccount(allAccounts[0]);
        activeAccount = allAccounts[0];
      }
    }

    return Promise.resolve(activeAccount);
  }

  getToken() {
    const scopes = [this.scopes];

    return this.app
      .acquireTokenSilent({ account: this.app.getActiveAccount() }) // This method is called for browser auth outside only Teams app.
      .then((authResponse) => authResponse.accessToken)
      .catch((error) => {
        if (error.errorMessage.indexOf("interaction_required") >= 0) {
          return this.app
            .acquireTokenPopup({
              scopes: scopes,
            })
            .then((authResponse) => authResponse.accessToken);
        } else {
          return Promise.reject(error);
        }
      });
  }

  getUserInfo(principalName) {
    return new Promise((resolve, reject) => {
      this.getToken().then((token) => {
        if (principalName) {
          let graphUrl = "https://graph.microsoft.com/v1.0/users/" + principalName;

          fetch(graphUrl, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
            },
          })
            .then((response) => response.json())
            .then((profile) => {
              resolve(profile);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    });
  }
}

export default MsalAuthService;
