import React, { useEffect } from "react";
import MsalAuthService from "../utils/msal-auth";
import Loader from "../components/Loader";

const clientId = process.env.REACT_APP_MSAL_CLIENT_ID;
const tenantId = process.env.REACT_APP_MSAL_TENANT_ID;
const applicationUri = process.env.REACT_APP_MSAL_APPLICATION_URI;

const BrowserRedirect = () => {
  useEffect(() => {
    const authService = new MsalAuthService(clientId, tenantId, applicationUri);

    authService
      .isCallback()
      .then((isCallback) => {
        if (!isCallback) {
          authService
            .getUser()
            .then((user) => {
              // Signed in the user automatically; we're ready to go
              console.log(user);
              window.location.href = "/signin";
            })
            .catch(() => {
              // Failed to sign in the user automatically; show login screen
              console.log("Failed");
              window.location.href = "/signin";
            });
        }
      })
      .catch((error) => {
        // Encountered error during redirect login flow; show error screen
        console.log(error);
        window.location.href = "/signin";
      });
  }, []);
  return (
    <div>
      <Loader />
    </div>
  );
};

export default BrowserRedirect;
