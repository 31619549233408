import React, { useState, useEffect, useCallback } from "react";
import { app, teamsCore } from "@microsoft/teams-js";
import MeetingRoughNotes from "./MeetingRoughNotes";
import { useRecoilState, useRecoilValue } from "recoil";
import { userProfileAtom } from "../atoms/user.atom";
import { teamsMeetingIdAtom } from "../atoms/meeting.atom";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import Loader from "./Loader";
import { toast } from "sonner";

dayjs.extend(timezone);
dayjs.extend(utc);

const SUPALOOPS_API = process.env.REACT_APP_SUPALOOPS_API_HOST;
const TEAMS_ADDON_API_KEY = process.env.REACT_APP_SUPALOOPS_API_KEY;

const Tab = () => {
  const userTimeZone = dayjs.tz.guess();
  const [meetingId, setMeetingId] = useRecoilState(teamsMeetingIdAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [meetingStartTimestamp, setMeetingStartTimestamp] = useState();
  const [meetingRoughNotes, setMeetingRoughNotes] = useState(null);

  const userProfile = useRecoilValue(userProfileAtom);

  const handleFetchingMeetingRoughNotes = () => {
    return new Promise((resolve, reject) => {
      const url = `${SUPALOOPS_API}/ma/teams/note?email=${userProfile.mail}&meeting_id=${meetingId}`;
      const myHeaders = new Headers();
      myHeaders.append("x-api-key", TEAMS_ADDON_API_KEY);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.error) {
            reject(responseJson.error);
          } else {
            resolve(responseJson);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleSavingMeetingRoughNotes = async (roughNotes) => {
    try {
      const response = await fetch(`${SUPALOOPS_API}/ma/teams/note`, {
        method: "POST",
        headers: {
          "x-api-key": TEAMS_ADDON_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailId: userProfile.mail,
          meetingId: meetingId,
          notes: roughNotes,
        }),
        cache: "default",
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const responseJson = await response.json();
      if (responseJson.error) {
        throw new Error(responseJson.error);
      }
    } catch (error) {
      console.error(":handleSavingMeetingRoughNotes ", error);
    }
  };

  useEffect(() => {
    app.initialize().then(() => {
      app.notifySuccess();

      app.getContext().then(async (context) => {
        const regex = /(?<=:).+?(?=@)/g;
        const meetingId = context.chat.id.match(regex)[0];
        setMeetingId(meetingId);
        if (!userProfile) {
          window.location.href = "/signin?inTeams=true";
        }
        handleFetchingMeetingRoughNotes()
          .then((response) => {
            if (response) {
              const { roughNotes, start_time: startTimestamp } = response;
              if (startTimestamp) {
                setMeetingStartTimestamp(startTimestamp);
              }

              const startTimestampDateObj = dayjs(startTimestamp * 1000).tz(userTimeZone, true);
              const currentTimeStampDiff = dayjs().tz(userTimeZone, true).diff(startTimestampDateObj, "seconds");

              const initialMeetingRoughNoteState = [
                {
                  content: "start writing here",
                  timestamp: currentTimeStampDiff > 0 ? currentTimeStampDiff.toFixed(2) : "0.00",
                },
              ];

              if (roughNotes && roughNotes.length > 0) {
                setMeetingRoughNotes(roughNotes);
              } else {
                setMeetingRoughNotes(initialMeetingRoughNoteState);
              }
            }
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("handleFetchingMeetingRoughNotes ", error);
            setIsLoading(false);
            setIsError(true);
            toast.error("Error fetching meeting rough notes");
          });
        if (context.page.frameContext === "sidePanel") {
          teamsCore.registerOnLoadHandler((context) => {
            app.notifySuccess();
          });

          teamsCore.registerBeforeUnloadHandler((readyToUnload) => {
            readyToUnload();
            return true;
          });
        }
      });
    });
  }, []);
  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <MeetingRoughNotes
        roughNotes={meetingRoughNotes}
        meetingId={meetingId}
        userEmail={userProfile.mail}
        startTimestamp={meetingStartTimestamp}
        onSave={(roughNotes) => {
          if (roughNotes) {
            setMeetingRoughNotes(roughNotes);
            handleSavingMeetingRoughNotes(roughNotes);
          }
        }}
      />
    </div>
  );
};

export default Tab;
