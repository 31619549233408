import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import { inTeams } from "./utils/inTeams";
import Tab from "./components/Tab";
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";
import TabConfig from "./pages/TabConfig";
import "./App.css";
import Signin from "./pages/Signin";
import AuthStart from "./pages/AuthStart";
import AuthEnd from "./pages/AuthEnd";
import BrowserRedirect from "./pages/BrowserRedirect";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const [initialized, setInitialized] = useState(false);
  const [appTheme, setAppTheme] = useState("");
  useEffect(() => {
    if (!initialized) {
      if (inTeams()) {
        console.log("App.js: initializing client SDK");
        microsoftTeams.app
          .initialize()
          .then(() => {
            console.log("App.js: initializing client SDK initialized");
            microsoftTeams.app.notifyAppLoaded();
            microsoftTeams.app.notifySuccess();
            setInitialized(true);
            microsoftTeams.app.getContext().then((context) => {
              // Applying default theme from app context property
              switch (context.app.theme) {
                case "dark":
                  setAppTheme("theme-dark");
                  break;
                case "default":
                  setAppTheme("theme-light");
                  break;
                case "contrast":
                  setAppTheme("theme-contrast");
                  break;
                default:
                  return setAppTheme("theme-light");
              }
            });
          })
          .catch((error) => console.error(error));
      } else {
        setInitialized(true);
      }
    }
  }, []);

  if (!initialized) {
    return <div />;
  }

  return (
    <FluentProvider
      theme={teamsLightTheme}
      style={{
        minHeight: "0px",
        position: "absolute",
        left: "0",
        right: "0",
        top: "0",
        bottom: "0",
        overflow: "hidden",
        backgroundColor: inTeams() ? "transparent" : "transparent",
      }}
    >
      <Router window={window} basename="/">
        <Routes>
          <Route exact path={"config"} element={<TabConfig />} />
          <Route exact path={"signin"} element={<Signin />} />
          <Route exact path={"auth-start"} element={<AuthStart />} />
          <Route exact path={"auth-end"} element={<AuthEnd />} />
          <Route exact path={"sidepanel"} element={<Tab />} />
          <Route exact path={"browser-redirect"} element={<BrowserRedirect />} />
        </Routes>
      </Router>
    </FluentProvider>
  );
}
