import React, { useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import * as msal from "@azure/msal-browser";

const clientId = process.env.REACT_APP_MSAL_CLIENT_ID;

export default function AuthEnd() {
  useEffect(() => {
    microsoftTeams.app.initialize().then(() => {
      microsoftTeams.app.getContext().then(async (context) => {
        const msalConfig = {
          auth: {
            clientId,
            authority: `https://login.microsoftonline.com/${context.tid}`,
            navigateToLoginRequestUrl: false,
          },
          cache: {
            cacheLocation: "sessionStorage",
          },
        };

        const msalInstance = new msal.PublicClientApplication(msalConfig);
        msalInstance
          .handleRedirectPromise()
          .then((tokenResponse) => {
            if (tokenResponse !== null) {
              microsoftTeams.authentication.notifySuccess(
                JSON.stringify({
                  sessionStorage: sessionStorage,
                })
              );
            } else {
              microsoftTeams.authentication.notifyFailure("Get empty response.");
            }
          })
          .catch((error) => {
            microsoftTeams.authentication.notifyFailure(JSON.stringify(error));
          });
      });
    });
  }, []);
  return <div></div>;
}
