import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Title1,
  Subtitle1,
} from "@fluentui/react-components";
import * as microsoftTeams from "@microsoft/teams-js";
import MsalAuthService from "./../utils/msal-auth";
import { Button } from "@fluentui/react-components";
import { useSearchParams } from "react-router-dom";
import Loader from "../components/Loader";
import { useRecoilState } from "recoil";
import { userProfileAtom } from "../atoms/user.atom";

const clientId = process.env.REACT_APP_MSAL_CLIENT_ID;
const tenantId = process.env.REACT_APP_MSAL_TENANT_ID;
const applicationUri = process.env.REACT_APP_MSAL_APPLICATION_URI;

const SUPALOOPS_API = process.env.REACT_APP_SUPALOOPS_API_HOST;
const TEAMS_ADDON_API_KEY = process.env.REACT_APP_SUPALOOPS_API_KEY;

const Signin = () => {
  const [searchParams] = useSearchParams();
  const [appTheme, setAppTheme] = useState("");
  const [logs, setLogs] = useState("Heelo logs");
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [showConsentButton, setShowConsentButton] = useState(false);
  const [showNotesButton, setShowNotesButton] = useState(false);
  const [showSigninContainer, setShowSigninContainer] = useState(false);
  const [userSignedIn, setUserSignedIn] = useState(false);
  const [userProfile, setUserProfile] = useRecoilState(userProfileAtom);

  const inTeams = searchParams.get("inTeams") === "true";
  console.log("SignIn: inTeams = " + inTeams);

  // Add text to the display in a <p> or other HTML element
  const display = useCallback((text, elementTag = "p") => {
    // Assuming you want to update logs based on the input text
    setLogs((prevLogs) => prevLogs + "\n" + text);
  }, []);

  const auth = useRef(new MsalAuthService(clientId, tenantId, applicationUri));

  function logout() {
    auth.current.logout();
  }

  function loginUser() {
    auth.current
      .login()
      .then((user) => {
        if (user) {
          setUserSignedIn(true);
          setUserProfile({
            displayName: user.name,
            email: user.username,
            givenName: user.name.split(" ")[0],
          });
          getMyProfile(user.localAccountId);
        } else {
          setUserSignedIn(false);
        }
      })
      .catch((err) => {
        setUserSignedIn(false);
      });
  }

  const getMyProfile = useCallback(async function (userId) {
    const user = await auth.current.getUserInfo(userId);
    if (user) {
      setUserProfile(user);
    }
  }, []);

  // 1. Get auth token
  // Ask Teams to get us a token from AAD

  const getClientSideToken = useCallback(() => {
    return new Promise((resolve, reject) => {
      // display("1. Get auth token from Microsoft Teams");

      microsoftTeams.authentication
        .getAuthToken()
        .then((result) => {
          // display(result);

          resolve(result);
        })
        .catch((error) => {
          reject("Error getting token: " + error);
        });
    });
  }, []);

  // 2. Exchange that token for a token with the required permissions
  //    using the web service (see /auth/token handler in app.js)
  function getServerSideToken(clientSideToken) {
    return new Promise((resolve, reject) => {
      microsoftTeams.app.getContext().then((context) => {
        if (context.page.frameContext === "sidePanel") {
          setShowNotesButton(true);
        }

        fetch(`${SUPALOOPS_API}/ma/teams/auth`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": TEAMS_ADDON_API_KEY,
          },
          body: JSON.stringify({
            tenantId: context.user.tenant.id,
            token: clientSideToken,
          }),
          cache: "default",
        })
          .then((response) => {
            return response.json();
          })
          .then((responseJson) => {
            if (responseJson.message === "invalid_grant") {
              throw responseJson.message;
            } else {
              const profile = responseJson;
              resolve(profile);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  }

  // 3. Get the server side token and use it to call the Graph API
  const showServerSideToken = useCallback(function showServerSideToken(data) {
    // display("2. Call https://graph.microsoft.com/v1.0/me/ with the server side token");
    if (data && data.id) {
      setUserSignedIn(true);
      setUserProfile(data);
    }
    return display(JSON.stringify(data, undefined, 4));
  }, []);

  // Show the consent pop-up
  function requestConsent() {
    return new Promise((resolve, reject) => {
      microsoftTeams.authentication
        .authenticate({
          url: window.location.origin + "/auth-start",
          width: 600,
          height: 535,
        })
        .then((result) => {
          let tokenData = result;
          resolve(tokenData);
        })
        .catch((reason) => {
          reject(JSON.stringify(reason));
        });
    });
  }

  function giveConsent() {
    requestConsent()
      .then((result) => {
        // window.location.href = "/signin?inTeams=true";
        // Consent succeeded
        // display(`Consent succeeded`);
        // offer to refresh the page
        setShowConsentButton(false);
      })
      .catch((error) => {
        // display(`ERROR ${error}`);
        // Consent failed - offer to refresh the page
        setShowConsentButton(false);
        setIsError(true);
      });
  }

  useEffect(() => {
    if (inTeams) {
      microsoftTeams.app
        .initialize()
        .then(() => {
          microsoftTeams.app.getContext().then((context) => {
            // Applying default theme from app context property
            switch (context.app.theme) {
              case "dark":
                setAppTheme("theme-dark");
                break;
              case "default":
                setAppTheme("theme-light");
                break;
              case "contrast":
                setAppTheme("theme-contrast");
                break;
              default:
                return setAppTheme("theme-light");
            }
          });

          getClientSideToken()
            .then((clientSideToken) => {
              return getServerSideToken(clientSideToken);
            })
            .then((profile) => {
              setUserProfile(profile);
              setIsLoading(false);
              return showServerSideToken(profile);
            })
            .catch((error) => {
              if (error === "invalid_grant") {
                display(`Error: ${error} - user or admin consent required`);
                // Display in-line button so user can consent
                setShowConsentButton(true);
              } else {
                // Something else went wrong
                display(`Error from web service: ${error}`);
              }
              setIsLoading(false);
            });
        })
        .catch((error) => {
          setIsError(true);
          display(`Error from Teams: ${error}`);
          console.error(error);
          setIsLoading(false);
        });
    } else {
      // const authService = new MsalAuthService(clientId, tenantId, applicationUri);
      // auth.current = authService;
      auth.current
        .isCallback()
        .then((isCallback) => {
          if (!isCallback) {
            auth.current
              .getUser()
              .then((user) => {
                // Signed in the user automatically; we're ready to go
                if (user) {
                  getMyProfile(user.localAccountId);
                  setUserSignedIn(true);
                  setUserProfile({
                    email: user.username,
                    displayName: user.name,
                    giveName: user.name.split(" ")[0],
                  });
                } else {
                  setUserSignedIn(false);
                  setShowSigninContainer(true);
                }
              })
              .catch(() => {
                setUserSignedIn(false);
                // Failed to sign in the user automatically; show login screen
                console.log("Failed");
                setShowSigninContainer(true);
              });
          }
        })
        .catch((error) => {
          // Encountered error during redirect login flow; show error screen
          console.log(error);
          setShowSigninContainer(true);
        });

      setIsLoading(false);
    }
  }, [getClientSideToken, inTeams, showServerSideToken, display, auth, getMyProfile, setUserProfile]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className={`flex flex-col ${showNotesButton ? "" : "px-8 mt-8 w-10/12 mx-auto"} ${appTheme}`}>
      {showConsentButton && (
        <Dialog open>
          <DialogSurface>
            <DialogBody>
              <DialogTitle>Almost there</DialogTitle>
              <DialogContent>
                Please provide the necessary permissions to use the application conveniently
              </DialogContent>
              <DialogActions>
                <Button appearance="primary" onClick={giveConsent}>
                  Consent
                </Button>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      )}

      {isError && (
        <Dialog open>
          <DialogSurface>
            <DialogBody>
              <DialogTitle>Supaloops Notes</DialogTitle>
              <DialogContent>
                We are unable to fetch your profile details. Please click on refresh button to get started.
              </DialogContent>
              <DialogActions>
                <Button appearance="primary" onClick={() => window.location.reload()}>
                  Refresh
                </Button>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      )}

      {showSigninContainer && !userSignedIn && (
        <div id="browser-signin-container">
          <div id="browser-login">
            <h2>Please click on Login button to sign in!</h2>
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mt-8 rounded browser-button"
              onClick={loginUser}
            >
              Login
            </button>
          </div>
          <div id="divGraphProfile" className="hidden"></div>
        </div>
      )}

      {userSignedIn && (
        <div className=" p-8 max-w-md w-full">
          <Title1 className="text-2xl font-bold mt-8 mb-4">Welcome to Supaloops Notes!</Title1>
          <div className="space-y-4 mt-8">
            <Subtitle1>Hello {userProfile.displayName},</Subtitle1>
            <p>Thank you for signing in. You are now ready to use our addon application.</p>
            <p>{showNotesButton ? "You can start taking notes now" : "Please add the app to a meeting or a chat"}</p>
          </div>
          {!inTeams && (
            <Button type="button" onClick={logout} style={{ marginTop: "2rem" }}>
              Log out
            </Button>
          )}
          {showNotesButton && (
            <Button
              type="button"
              onClick={() => {
                window.location.assign("/sidepanel?inTeams=true");
              }}
              style={{ marginTop: "2rem" }}
            >
              Take Notes
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Signin;
