import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  storage: sessionStorage,
});

export const userProfileAtom = atom({
  key: "UserProfile",
  default: false,
  effects_UNSTABLE: [persistAtom],
});
