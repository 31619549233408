import React, { useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import * as msal from "@azure/msal-browser";

const clientId = process.env.REACT_APP_MSAL_CLIENT_ID;
// const tenantId = process.env.REACT_APP_MSAL_TENANT_ID;
// const authority = "https://login.microsoftonline.com/" + tenantId;

export default function AuthStart() {
  useEffect(() => {
    microsoftTeams.app.initialize().then(() => {
      // Get the tab context, and use the information to navigate to Azure AD login page
      microsoftTeams.app.getContext().then(async (context) => {
        // var currentURL = new URL(window.location);
        var scope = "User.Read email openid profile offline_access";
        var loginHint = context.user.loginHint;

        const msalConfig = {
          auth: {
            clientId,
            authority: `https://login.microsoftonline.com/${context.user.tenant.id}`,
            navigateToLoginRequestUrl: false,
          },
          cache: {
            cacheLocation: "sessionStorage",
          },
        };

        const msalInstance = new msal.PublicClientApplication(msalConfig);
        const scopesArray = scope.split(" ");
        const scopesRequest = {
          scopes: scopesArray,
          redirectUri: window.location.origin + `/auth-end`,
          loginHint: loginHint,
        };
        await msalInstance.loginRedirect(scopesRequest);
      });
    });
  }, []);
  return <div></div>;
}
