import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  storage: sessionStorage,
});

export const teamsMeetingContextAtom = atom({
  key: "TeamsMeetingContext",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const teamsMeetingIdAtom = atom({
  key: "TeamsMeetingId",
  default: false,
  effects_UNSTABLE: [persistAtom],
});
